<template>
  <footer class="mm-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 text-right">
          {{ $t("generalMsgs.copyright") }} 2021 ©
          <a href="#" @click.prevent="">{{ appName }}</a>
          {{ $t("generalMsgs.allRightsRes") }}
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  prop: {},
  computed: {
    ...mapGetters({
      appName: "appName",
    }),
  },
};
</script>