<template>
  <div class="container-fluid">
    <div class="mm-top-navbar">
      <div class="mm-navbar-custom">
        <nav class="navbar navbar-expand-lg navbar-light p-0">
          <div
            class="
              mm-navbar-logo
              d-flex
              align-items-center
              justify-content-between
            "
            @click="miniSidebar"
          >
            <i class="fas fa-bars wrapper-menu clickable-item"></i>
            <router-link :to="{ name: 'order-overview' }">
              <!-- <strong>Werkstattmanager</strong> -->
              <img src="@/assets/images/logo-hz-30.png" class="img-fluid rounded-normal" alt="logo" />
            </router-link>
          </div>

          <div class="mm-search-bar device-search m-auto col-md-6">
            <!-- Search or Any center content here. -->
            <div class="searchbox" v-if="$route.name !== 'order-overview'">
              <a class="search-link" href="#" @click.prevent><i class="fas fa-search"></i></a>
              <v-select
                class="search-input v-select-search"
                :placeholder="`${$t('orderMsgs.searchOrders')}...`"
                :options="allOrders"
                label="order_number"
                v-model="order"
                :loading="isLoading"
                :filterable="false"
                :clearSearchOnSelect="false"
                @search="onSearch"
                @option:selected="openOrderDetailsModal"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>

                <template slot="option" slot-scope="option">
                  {{ option.order_number }} - {{ option.customer.name }}
                </template>

                <template slot="no-options">
                  {{ orderSearchNoResult ? $t('orderMsgs.noOrderFoundSearch') : $t('generalMsgs.typeToSearch') }}
                </template>

                <template #list-footer>
                  <li ref="load" class="vs-search-more" v-if="firstLoad">
                    {{ $t('orderMsgs.typeToSearchMore') }}
                  </li>
                </template>
              </v-select>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <ModeSwitch />
            <b-navbar-toggle target="nav-collapse" class="mr-2 text-primary">
              <template #default="{ expanded }">
                <i v-if="expanded" class="fas fa-caret-up" />
                <i v-else class="fas fa-caret-down" />
              </template>
            </b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <ul class="navbar-nav ml-auto navbar-list align-items-center">
                <!-- Search for mobile -->
                <li class="nav-item nav-icon search-content" v-nav-toggle v-if="$route.name !== 'order-overview'">
                  <a
                    href="#"
                    class="search-toggle rounded dropdown-toggle"
                    id="dropdownSearch"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @click.prevent
                  >
                    <svg
                      class="svg-icon text-primary"
                      id="h-suns"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </a>

                  <div class="mm-search-bar dropdown-menu" aria-labelledby="dropdownSearch">
                    <div class="searchbox p-2">
                      <div class="position-relative">
                        <a class="search-link" href="#" @click.prevent><i class="fas fa-search"/></a>
                        <v-select
                          class="search-input v-select-search"
                          :placeholder="`${$t('orderMsgs.searchOrders')}...`"
                          :options="allOrders"
                          label="order_number"
                          v-model="order"
                          :loading="isLoading"
                          :filterable="false"
                          @search="onSearch"
                          @option:selected="openOrderDetailsModal"
                        >
                          <template #search="{ attributes, events }">
                            <input class="vs__search" v-bind="attributes" v-on="events" />
                          </template>

                          <template slot="option" slot-scope="option">
                            {{ option.order_number }} -
                            {{ option.customer.name }}
                          </template>

                          <template slot="no-options">
                            <span class="ml-3">{{
                              orderSearchNoResult ? $t('orderMsgs.noOrderFoundSearch') : $t('generalMsgs.typeToSearch')
                            }}</span>
                          </template>

                          <template #list-footer>
                            <li ref="load" class="vs-search-more" v-if="firstLoad">
                              {{ $t('orderMsgs.typeToSearchMore') }}
                            </li>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="nav-item nav-icon dropdown" v-nav-toggle>
                  <a
                    href="#"
                    class="search-toggle dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @click.prevent
                  >
                    <!-- <i
                    class="max fas fa-language fa-lg text-primary mt-1"
                  /> -->
                    <svg
                      class="svg-icon text-primary"
                      id="d-1-g"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span class="bg-primary"></span>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <li
                      class="dropdown-item d-flex svg-icon"
                      :class="currLanguage === LANG_ABBREVIATIONS.EN ? 'active' : null"
                      @click.prevent="changeLang(LANG_ABBREVIATIONS.EN)"
                    >
                      <i class="max fas fa-language text-primary mt-1" />
                      <!-- <router-link :to="{ name: 'my-profile' }"
                      >English</router-link
                    > -->
                      <a href="#" @click.prevent="">{{ $t('langNames.english') }}</a>
                    </li>

                    <li
                      class="dropdown-item d-flex svg-icon"
                      :class="currLanguage === LANG_ABBREVIATIONS.DE ? 'active' : null"
                      @click.prevent="changeLang(LANG_ABBREVIATIONS.DE)"
                    >
                      <i class="max fas fa-language text-primary mt-1" />
                      <a href="#" @click.prevent="">{{ $t('langNames.german') }}</a>
                    </li>
                  </ul>
                </li>

                <!-- Notifications -->
                <Notifications
                  v-if="getLoggedInUser.role !== USER_ROLES.CALCULATOR"
                  class="nav-item nav-icon dropdown"
                  @showOrderModal="openOrderDetailsModal"
                />

                <Fullscreen class="nav-item nav-icon dropdown full-screen" extraclass="nav-item nav-icon dropdown" />

                <li class="nav-item nav-icon dropdown" v-nav-toggle>
                  <a
                    class="nav-item nav-icon dropdown-toggle pr-0 search-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <b-avatar
                      v-if="getLoggedInUser.first_name && getLoggedInUser.last_name"
                      variant="primary"
                      size="50px"
                      :text="
                        `${getLoggedInUser.first_name[0].toUpperCase()}${getLoggedInUser.last_name[0].toUpperCase()}`
                      "
                    />
                    <b-avatar variant="primary" size="50px" v-else />
                    <!-- <img
                    src="@/assets/images/user/1.jpg"
                    class="img-fluid avatar-rounded"
                    alt="user"
                  /> -->
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <li class="dropdown-item d-flex svg-icon" @click.prevent="redirectTo('my-profile')">
                      <svg
                        class="svg-icon mr-0 text-primary"
                        id="h-01-p"
                        width="20"
                        height="25"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <!-- <router-link :to="{ name: 'my-profile' }">{{
                      $t("userMsgs.myProfile")
                    }}</router-link> -->
                      <a href="#" @click.prevent="">{{ $t('userMsgs.myProfile') }}</a>
                    </li>

                    <li class="dropdown-item d-flex svg-icon border-top" @click.prevent="logoutUser">
                      <svg
                        class="svg-icon mr-0 text-primary"
                        id="h-05-p"
                        width="20"
                        height="25"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>

                      <a href="#" @click.prevent="">{{ $t('authMsgs.logout') }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </b-collapse>
          </div>
        </nav>
      </div>
    </div>
    <order-detail-modal
      :showModal.sync="modals.showOrderDetailsModal"
      :orderId="modals.selectedOrderDetailsId"
      :commentId="modals.commentId"
      :orderNum="modals.selectedOrderNum"
      :selectedTabIndex="modals.selectedTabIndex"
      @close="hideOrderDetailsModal"
    />
  </div>
</template>
<script>
import vSelect from 'vue-select';
import { core } from '@/config/pluginInit';
import Fullscreen from '../../fullscreen/Fullscreen';
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { LANG_ABBREVIATIONS, USER_ROLES } from '../../../common/constants';
import OrderDetailModal from '../../../views/Orders/OrderDetail/OrderDetailModal.vue';
import Notifications from './components/Notifications.vue';

export default {
  name: 'Header',
  components: { Fullscreen, vSelect, OrderDetailModal, Notifications },

  data() {
    return {
      allOrders: [],
      order: null,
      currentPage: 1,
      pageSize: 30,
      firstLoad: false,
      orderSearchNoResult: false,
      isLoading: false,

      modals: {
        selectedOrderNum: null,
        showOrderDetailsModal: false,
        selectedOrderDetailsId: null,
        selectedTabIndex: 0,
        commentId: 0
      },
      LANG_ABBREVIATIONS,
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters({
      // logo: "darklogo",
      // sidelogo: "logo",
      getLoggedInUser: 'getLoggedInUser',
      currLanguage: 'getCurrLanguage'
    })
  },
  methods: {
    ...mapActions(['logout', 'changeCurrLang', 'getAllOrders']),

    miniSidebar() {
      core.triggerSet();
    },

    redirectTo(urlName) {
      if (this.$route.name !== urlName) {
        this.$router.push({ name: urlName });
      }
    },

    logoutUser() {
      this.logout();
      this.$router.push({ name: 'login' });
    },

    changeLang(lang) {
      this.$i18n.locale = lang;
      this.changeCurrLang(lang);
    },

    openOrderDetailsModal(orderRow) {
      this.modals.selectedOrderDetailsId = orderRow.id;
      this.modals.selectedOrderNum = orderRow.order_number;
      this.modals.showOrderDetailsModal = true;
      this.modals.selectedTabIndex = orderRow.selectedTab || 0;
      this.modals.commentId = orderRow.commentId || 0;
    },

    hideOrderDetailsModal() {
      this.modals.showOrderDetailsModal = false;
      this.modals.selectedOrderDetailsId = this.modals.selectedOrderNum = this.order = null;
      this.modals.selectedTabIndex = this.modals.commentId = 0;
    },

    async fetchAndSetOrders(pageNum = 1, params = {}, firstLoad = false, refillOrders = true) {
      this.isLoading = true;

      const response = await this.getAllOrders({
        limit: firstLoad ? 10 : this.pageSize,
        offset: (pageNum - 1) * this.pageSize,
        ...params
      });
      // this.allOrders = response.data;

      if (refillOrders) {
        this.allOrders = response.data.results;
      } else {
        this.allOrders = this.allOrders.concat(response.data.results);
      }
      this.currentPage = pageNum;
      this.totalOrders = response.data.count;

      if (!this.totalOrders) {
        this.orderSearchNoResult = true;
      }

      if (firstLoad) {
        // Add this if default selection of latest order is required.
        // this.order = this.allOrders[0];
        this.firstLoad = true;
      } else {
        this.firstLoad = false;
      }

      this.isLoading = false;
    },

    onSearch(search) {
      this.debouncedSearchOrders(search, this);
    },

    debouncedSearchOrders: debounce(async (search, vm) => {
      vm.orderSearchNoResult = false;
      if (search) {
        vm.fetchAndSetOrders(1, { ...(search && { search }) });
      } else {
        vm.allOrders = [];
      }
    }, 500)
  }
};
</script>
