var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mm-sidebar sidebar-default"},[_c('div',{staticClass:"mm-sidebar-logo d-flex align-items-center justify-content-between"},[_c('router-link',{staticClass:"header-logo",attrs:{"to":{ name: 'order-overview' }}},[_c('img',{staticClass:"img-fluid rounded-normal",attrs:{"src":require("@/assets/images/logo-hz-30.png"),"alt":"logo"}})]),_c('div',{staticClass:"side-menu-bt-sidebar",on:{"click":_vm.miniSidebar}},[_c('i',{staticClass:"fas fa-bars wrapper-menu"})])],1),_c('div',{staticClass:"data-scrollbar",attrs:{"data-scroll":"1","id":"sidebar-scrollbar"}},[_c('nav',{staticClass:"mm-sidebar-menu h-100"},[_c('ul',{staticClass:"side-menu d-flex flex-column h-100",attrs:{"id":"mm-sidebar-toggle"}},[(
            [_vm.USER_ROLES.ADMIN, _vm.USER_ROLES.OFFICE_WORKER, _vm.USER_ROLES.PROJECT_MANAGER].includes(_vm.getLoggedInUser.role)
          )?_c('li',{class:_vm.checkActive('order-overview') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'order-overview' }}},[_c('i',{staticClass:"fas fa-home text-primary"}),_c('span',[_vm._v(_vm._s(_vm.$t('overview')))])])],1):_c('li',{class:_vm.checkActive('list-bills') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'list-bills' }}},[_c('i',{staticClass:"fas fa-file-invoice text-primary"}),_c('span',[_vm._v(_vm._s(_vm.$t('billPaperMsgs.bills')))])])],1),(
            [_vm.USER_ROLES.ADMIN, _vm.USER_ROLES.OFFICE_WORKER, _vm.USER_ROLES.PROJECT_MANAGER].includes(_vm.getLoggedInUser.role)
          )?_c('li',{class:_vm.checkActive(_vm.orders) ? 'active' : ''},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.orders",modifiers:{"orders":true}}],staticClass:"svg-icon"},[_c('i',{staticClass:"fas fa-shopping-cart text-primary"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('orderMsgs.orders')))]),_c('i',{staticClass:"fas fa-angle-right mm-arrow-right arrow-active"}),_c('i',{staticClass:"fas fa-angle-down mm-arrow-right arrow-hover"})]),_c('b-collapse',{staticClass:"submenu",attrs:{"tag":"ul","id":"orders","accordion":"my-accordion","visible":_vm.checkActive(_vm.orders)},on:{"shown":function($event){return _vm.showCollapse('orders')},"hide":_vm.hideCollapse}},[_c('li',{class:_vm.checkActive('list-orders') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'list-orders' }}},[_c('i',{staticClass:"fas fa-shopping-cart text-primary"}),_c('span',{},[_vm._v(_vm._s(_vm.$t('orderMsgs.listOrders')))])])],1),_c('li',{class:_vm.checkActive('add-order') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'add-order' }}},[_c('i',{staticClass:"fas fa-cart-plus text-primary"}),_c('span',{},[_vm._v(_vm._s(_vm.$t('orderMsgs.addOrder')))])])],1)])],1):(_vm.getLoggedInUser.role === _vm.USER_ROLES.CALCULATOR)?_c('li',{class:_vm.checkActive('order-calc-list') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'order-calc-list' }}},[_c('i',{staticClass:"fas fa-cart-plus text-primary"}),_c('span',[_vm._v(_vm._s(_vm.$t('orderMsgs.orders')))])])],1):_vm._e(),(
            [_vm.USER_ROLES.ADMIN, _vm.USER_ROLES.OFFICE_WORKER, _vm.USER_ROLES.PROJECT_MANAGER].includes(_vm.getLoggedInUser.role)
          )?_c('li',{class:_vm.checkActive(_vm.customerInvoices) ? 'active' : ''},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.customerInvoices",modifiers:{"customerInvoices":true}}],staticClass:"svg-icon"},[_c('i',{staticClass:"fas fa-file-invoice-dollar text-primary"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('customerInvoiceMsgs.customerInvoices')))]),_c('i',{staticClass:"fas fa-angle-right mm-arrow-right arrow-active"}),_c('i',{staticClass:"fas fa-angle-down mm-arrow-right arrow-hover"})]),_c('b-collapse',{staticClass:"submenu",attrs:{"tag":"ul","id":"customerInvoices","accordion":"my-accordion","visible":_vm.checkActive(_vm.customerInvoices)},on:{"shown":function($event){return _vm.showCollapse('customerInvoices')},"hide":_vm.hideCollapse}},[_c('li',{class:_vm.checkActive('list-customer-invoices') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'list-customer-invoices' }}},[_c('i',{staticClass:"fas fa-file-invoice-dollar text-primary"}),_c('span',{},[_vm._v(_vm._s(_vm.$t('customerInvoiceMsgs.listCustomerInvoices')))])])],1),_c('li',{class:_vm.checkActive('add-customer-invoice') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'add-customer-invoice' }}},[_c('i',{staticClass:"fas fa-plus-circle text-primary"}),_c('span',{},[_vm._v(_vm._s(_vm.$t('customerInvoiceMsgs.addCustomerInvoice')))])])],1),(
                [_vm.USER_ROLES.ADMIN, _vm.USER_ROLES.OFFICE_WORKER, _vm.USER_ROLES.PROJECT_MANAGER].includes(
                  _vm.getLoggedInUser.role
                )
              )?_c('li',{class:_vm.showExportInvoicesModal ? 'active' : ''},[_c('a',{staticClass:"svg-icon",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openExportInvoicesModal($event)}}},[_c('i',{staticClass:"fas fa-file-download text-primary"}),_c('span',{},[_vm._v(_vm._s(_vm.$t('customerInvoiceMsgs.exportInvoices')))])])]):_vm._e()])],1):_vm._e(),(
            [_vm.USER_ROLES.ADMIN, _vm.USER_ROLES.OFFICE_WORKER, _vm.USER_ROLES.PROJECT_MANAGER].includes(_vm.getLoggedInUser.role)
          )?_c('li',{class:_vm.checkActive('list-bills') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'list-bills' }}},[_c('i',{staticClass:"fas fa-file-invoice text-primary"}),_c('span',[_vm._v(_vm._s(_vm.$t('billPaperMsgs.bills')))])])],1):_vm._e(),(
            [_vm.USER_ROLES.ADMIN, _vm.USER_ROLES.OFFICE_WORKER, _vm.USER_ROLES.PROJECT_MANAGER].includes(_vm.getLoggedInUser.role)
          )?_c('li',{staticClass:"mt-auto",class:_vm.checkActive(_vm.settings) ? 'active' : ''},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.settings",modifiers:{"settings":true}}],staticClass:"svg-icon"},[_c('i',{staticClass:"fas fa-cog text-primary"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('settings')))]),_c('i',{staticClass:"fas fa-angle-right mm-arrow-right arrow-active"}),_c('i',{staticClass:"fas fa-angle-down mm-arrow-right arrow-hover"})]),_c('b-collapse',{staticClass:"submenu",attrs:{"tag":"ul","id":"settings","accordion":"my-accordion","visible":_vm.checkActive(_vm.settings)},on:{"shown":function($event){return _vm.showCollapse('settings')},"hide":_vm.hideCollapse}},[(_vm.getLoggedInUser.role === _vm.USER_ROLES.ADMIN)?_c('li',{class:_vm.checkActive(_vm.user) ? 'active' : ''},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.users",modifiers:{"users":true}}],staticClass:"svg-icon"},[_c('i',{staticClass:"fas fa-user text-primary"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('userMsgs.users')))]),_c('i',{staticClass:"fas fa-angle-right mm-arrow-right arrow-active"}),_c('i',{staticClass:"fas fa-angle-down mm-arrow-right arrow-hover"})]),_c('b-collapse',{staticClass:"submenu",attrs:{"tag":"ul","id":"users","accordion":"child-accordion"}},[_c('li',{class:_vm.checkActive('list-users') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'list-users' }}},[_c('i',{staticClass:"far fa-list-alt text-primary"}),_c('span',{},[_vm._v(_vm._s(_vm.$t('userMsgs.listUsers')))])])],1),_c('li',{class:_vm.checkActive('add-user') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'add-user' }}},[_c('i',{staticClass:"fas fa-user-plus text-primary"}),_c('span',{},[_vm._v(_vm._s(_vm.$t('userMsgs.addUser')))])])],1)])],1):_vm._e(),(
                [_vm.USER_ROLES.ADMIN, _vm.USER_ROLES.OFFICE_WORKER, _vm.USER_ROLES.PROJECT_MANAGER].includes(
                  _vm.getLoggedInUser.role
                )
              )?_c('li',{class:_vm.checkActive(_vm.customers) ? 'active' : ''},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.customers",modifiers:{"customers":true}}],staticClass:"svg-icon"},[_c('i',{staticClass:"fas fa-briefcase text-primary"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('customerMsgs.customers')))]),_c('i',{staticClass:"fas fa-angle-right mm-arrow-right arrow-active"}),_c('i',{staticClass:"fas fa-angle-down mm-arrow-right arrow-hover"})]),_c('b-collapse',{staticClass:"submenu",attrs:{"tag":"ul","id":"customers","accordion":"child-accordion"}},[_c('li',{class:_vm.checkActive('list-customers') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'list-customers' }}},[_c('i',{staticClass:"fas fa-briefcase text-primary"}),_c('span',{},[_vm._v(_vm._s(_vm.$t('customerMsgs.listCustomers')))])])],1),_c('li',{class:_vm.checkActive('add-customer') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'add-customer' }}},[_c('i',{staticClass:"fas fa-plus-circle text-primary"}),_c('span',{},[_vm._v(_vm._s(_vm.$t('customerMsgs.addCustomer')))])])],1)])],1):_vm._e(),(
                [_vm.USER_ROLES.ADMIN, _vm.USER_ROLES.OFFICE_WORKER, _vm.USER_ROLES.PROJECT_MANAGER].includes(
                  _vm.getLoggedInUser.role
                )
              )?_c('li',{class:_vm.checkActive('list-hourly-wages') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'list-hourly-wages' }}},[_c('i',{staticClass:"fas fa-money-check-alt text-primary"}),_c('span',[_vm._v(_vm._s(_vm.$t('hourlyWageMsgs.hourlyWage')))])])],1):_vm._e(),(_vm.getLoggedInUser.role === _vm.USER_ROLES.ADMIN)?_c('li',{class:_vm.checkActive('list-email-alerts') ? 'active' : ''},[_c('router-link',{staticClass:"svg-icon",attrs:{"to":{ name: 'list-email-alerts' }}},[_c('i',{staticClass:"fas fa-envelope text-primary"}),_c('span',[_vm._v(_vm._s(_vm.$t('alertsConfigurationMsgs.emailAlerts')))])])],1):_vm._e()])],1):_vm._e()])]),_c('div',{staticClass:"pt-5 pb-2"})]),_c('order-results-modal',{attrs:{"showModal":_vm.showOrderResultsModal},on:{"update:showModal":function($event){_vm.showOrderResultsModal=$event},"update:show-modal":function($event){_vm.showOrderResultsModal=$event},"close":_vm.hideOrderResultsModal}}),_c('invoices-export-modal',{attrs:{"showModal":_vm.showExportInvoicesModal},on:{"update:showModal":function($event){_vm.showExportInvoicesModal=$event},"update:show-modal":function($event){_vm.showExportInvoicesModal=$event},"close":_vm.hideExportInvoicesModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }