<template>
  <li class="mm-sub-card p-2" :class="!notification.is_read ? 'active' : ''" @click="setNotificationRead">
    <div class="media align-items-center">
      <div class="avatar-40">
        <b-avatar
          v-if="notification.notifier.first_name && notification.notifier.last_name"
          variant="primary"
          size="30px"
          :text="
            `${notification.notifier.first_name[0].toUpperCase()}${notification.notifier.last_name[0].toUpperCase()}`
          "
        />
        <b-avatar variant="primary" size="30px" v-else />
      </div>
      <div class="text-primary ml-2">
        <h6 class="mb-0">
          <strong>{{ notification.notifier.first_name }} {{ notification.notifier.last_name }} </strong>
          {{ $t(`notificationMsgs.notificationTypes.${notification.notification}`) }}
          <strong>{{ `${NOTIFICATION_EXTRA_MAPPINGS(notification.notification, notification.extra)}` }} </strong>
        </h6>
        <small class="mb-0 text-sm text-muted">{{ formatDateTime(notification.created_at) }}</small>
      </div>
    </div>
  </li>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDateTime, NOTIFICATION_EXTRA_MAPPINGS } from '../../../../common/utils';

export default {
  name: 'notification-list-item',

  props: {
    notification: Object
  },

  data() {
    return {
      orderDetailModal: {
        showOrderDetailsModal: false,
        orderId: null,
        orderNum: null,
        selectedTabIndex: 0
      }
    };
  },

  methods: {
    ...mapActions(['updateNotification']),
    NOTIFICATION_EXTRA_MAPPINGS,
    formatDateTime,

    async setNotificationRead() {
      this.$emit(
        'notificationRead',
        this.notification.notification,
        this.notification.extra,
        this.notification.object_id
      );
      if (!this.notification.is_read) {
        const data = {
          is_read: true
        };
        await this.updateNotification({ id: this.notification.id, data });
      }
    }
  }
};
</script>
