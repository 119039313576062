<template>
  <div class="mm-sidebar sidebar-default">
    <div class="mm-sidebar-logo d-flex align-items-center justify-content-between">
      <router-link :to="{ name: 'order-overview' }" class="header-logo">
        <!-- <strong :class="isCollapsed ? 'd-none' : ''">Werkstattmanager</strong> -->
        <img src="@/assets/images/logo-hz-30.png" class="img-fluid rounded-normal" alt="logo" />
      </router-link>
      <div class="side-menu-bt-sidebar" @click="miniSidebar">
        <i class="fas fa-bars wrapper-menu"></i>
      </div>
    </div>
    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
      <nav class="mm-sidebar-menu h-100">
        <ul id="mm-sidebar-toggle" class="side-menu d-flex flex-column h-100">
          <!-- Order Overview -->
          <li
            :class="checkActive('order-overview') ? 'active' : ''"
            v-if="
              [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)
            "
          >
            <router-link :to="{ name: 'order-overview' }" class="svg-icon">
              <i class="fas fa-home text-primary" />
              <span>{{ $t('overview') }}</span>
            </router-link>
          </li>

          <!-- Home page for other users -->
          <li :class="checkActive('list-bills') ? 'active' : ''" v-else>
            <router-link :to="{ name: 'list-bills' }" class="svg-icon">
              <i class="fas fa-file-invoice text-primary" />
              <span>{{ $t('billPaperMsgs.bills') }}</span>
            </router-link>
          </li>

          <!-- Dashboard -->
          <!-- <li
            :class="checkActive('dashboard') ? 'active' : ''"
            v-if="getLoggedInUser.role === USER_ROLES.ADMIN"
          >
            <router-link :to="{ name: 'dashboard' }" class="svg-icon">
              <i class="fas fa-chart-bar text-primary" />
              <span>{{ $t("dashboard") }}</span>
            </router-link>
          </li> -->

          <!-- Orders -->
          <li
            :class="checkActive(orders) ? 'active' : ''"
            v-if="
              [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)
            "
          >
            <a v-b-toggle.orders class="svg-icon">
              <i class="fas fa-shopping-cart text-primary"></i>
              <span class="ml-2">{{ $t('orderMsgs.orders') }}</span>
              <i class="fas fa-angle-right mm-arrow-right arrow-active"></i>
              <i class="fas fa-angle-down mm-arrow-right arrow-hover"></i>
            </a>
            <b-collapse
              @shown="showCollapse('orders')"
              @hide="hideCollapse"
              tag="ul"
              id="orders"
              class="submenu"
              accordion="my-accordion"
              :visible="checkActive(orders)"
            >
              <li :class="checkActive('list-orders') ? 'active' : ''">
                <router-link :to="{ name: 'list-orders' }" class="svg-icon">
                  <i class="fas fa-shopping-cart text-primary" />
                  <span class="">{{ $t('orderMsgs.listOrders') }}</span>
                </router-link>
              </li>
              <li :class="checkActive('add-order') ? 'active' : ''">
                <router-link :to="{ name: 'add-order' }" class="svg-icon">
                  <i class="fas fa-cart-plus text-primary"></i><span class="">{{ $t('orderMsgs.addOrder') }}</span>
                </router-link>
              </li>

              <!-- <li :class="showOrderResultsModal ? 'active' : ''" v-if="getLoggedInUser.role === USER_ROLES.ADMIN">
                <a href="#" class="svg-icon" @click.prevent="openOrderResultsModal">
                  <i class="fas fa-file-download text-primary"></i>
                  <span class="">{{ $t('orderMsgs.exportReport') }}</span>
                </a>
              </li> -->
            </b-collapse>
          </li>
          <li
            v-else-if="getLoggedInUser.role === USER_ROLES.CALCULATOR"
            :class="checkActive('order-calc-list') ? 'active' : ''"
          >
            <router-link :to="{ name: 'order-calc-list' }" class="svg-icon">
              <i class="fas fa-cart-plus text-primary" />
              <span>{{ $t('orderMsgs.orders') }}</span>
            </router-link>
          </li>

          

          <!-- Customer Invoices -->
          <li
            :class="checkActive(customerInvoices) ? 'active' : ''"
            v-if="
              [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)
            "
          >
            <a v-b-toggle.customerInvoices class="svg-icon">
              <i class="fas fa-file-invoice-dollar text-primary"></i>
              <span class="ml-2">{{ $t('customerInvoiceMsgs.customerInvoices') }}</span>
              <i class="fas fa-angle-right mm-arrow-right arrow-active"></i>
              <i class="fas fa-angle-down mm-arrow-right arrow-hover"></i>
            </a>
            <b-collapse
              @shown="showCollapse('customerInvoices')"
              @hide="hideCollapse"
              tag="ul"
              id="customerInvoices"
              class="submenu"
              accordion="my-accordion"
              :visible="checkActive(customerInvoices)"
            >
              <li :class="checkActive('list-customer-invoices') ? 'active' : ''">
                <router-link :to="{ name: 'list-customer-invoices' }" class="svg-icon">
                  <i class="fas fa-file-invoice-dollar text-primary" />
                  <span class="">{{ $t('customerInvoiceMsgs.listCustomerInvoices') }}</span>
                </router-link>
              </li>
              <li :class="checkActive('add-customer-invoice') ? 'active' : ''">
                <router-link :to="{ name: 'add-customer-invoice' }" class="svg-icon">
                  <i class="fas fa-plus-circle text-primary"></i
                  ><span class="">{{ $t('customerInvoiceMsgs.addCustomerInvoice') }}</span>
                </router-link>
              </li>
              <li
                :class="showExportInvoicesModal ? 'active' : ''"
                v-if="
                  [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(
                    getLoggedInUser.role
                  )
                "
              >
                <a href="#" class="svg-icon" @click.prevent="openExportInvoicesModal">
                  <i class="fas fa-file-download text-primary"></i>
                  <span class="">{{ $t('customerInvoiceMsgs.exportInvoices') }}</span>
                </a>
              </li>
            </b-collapse>
          </li>

          <!-- Bill Papers -->
          <li
            :class="checkActive('list-bills') ? 'active' : ''"
            v-if="
              [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)
            "
          >
            <router-link :to="{ name: 'list-bills' }" class="svg-icon">
              <i class="fas fa-file-invoice text-primary" />
              <span>{{ $t('billPaperMsgs.bills') }}</span>
            </router-link>
          </li>

          <!-- Inventory Receipts -->
          <!-- <li
            :class="checkActive(inventoryReceipts) ? 'active' : ''"
            v-if="
              [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)
            "
          >
            <a v-b-toggle.inventoryReceipts class="svg-icon">
              <i class="fas fa-receipt text-primary"></i>
              <span class="ml-2">{{ $t('inventoryReceiptMsgs.inventoryReceipts') }}</span>
              <i class="fas fa-angle-right mm-arrow-right arrow-active"></i>
              <i class="fas fa-angle-down mm-arrow-right arrow-hover"></i>
            </a>
            <b-collapse
              @shown="showCollapse('inventoryReceipts')"
              @hide="hideCollapse"
              tag="ul"
              id="inventoryReceipts"
              class="submenu"
              accordion="my-accordion"
              :visible="checkActive(inventoryReceipts)"
            >
              <li :class="checkActive('list-inventory-receipts') ? 'active' : ''">
                <router-link :to="{ name: 'list-inventory-receipts' }" class="svg-icon">
                  <i class="fas fa-receipt text-primary" />
                  <span class="">{{ $t('inventoryReceiptMsgs.listInventoryReceipts') }}</span>
                </router-link>
              </li>
              <li :class="checkActive('add-inventory-receipt') ? 'active' : ''">
                <router-link :to="{ name: 'add-inventory-receipt' }" class="svg-icon">
                  <i class="fas fa-plus-circle text-primary"></i
                  ><span class="">{{ $t('inventoryReceiptMsgs.addInventoryReceipt') }}</span>
                </router-link>
              </li>
            </b-collapse>
          </li> -->

          <!-- Inventory -->
          <!-- <li
            :class="checkActive('list-inventory') ? 'active' : ''"
            v-if="
              [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER, USER_ROLES.PLANT_MANAGER].includes(getLoggedInUser.role)
            "
          >
            <router-link :to="{ name: 'list-inventory' }" class="svg-icon">
              <i class="fas fa-truck-loading text-primary" />
              <span>{{ $t('inventoryMsgs.inventory') }}</span>
            </router-link>
          </li> -->

          <!-- Settings -->
          <li
            v-if="
              [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(getLoggedInUser.role)
            "
            class="mt-auto"
            :class="checkActive(settings) ? 'active' : ''"
          >
            <a v-b-toggle.settings class="svg-icon">
              <i class="fas fa-cog text-primary" />
              <span class="ml-2">{{ $t('settings') }}</span>
              <i class="fas fa-angle-right mm-arrow-right arrow-active"></i>
              <i class="fas fa-angle-down mm-arrow-right arrow-hover"></i>
            </a>
            <b-collapse
              @shown="showCollapse('settings')"
              @hide="hideCollapse"
              tag="ul"
              id="settings"
              class="submenu"
              accordion="my-accordion"
              :visible="checkActive(settings)"
            >
              <!-- Users -->
              <li :class="checkActive(user) ? 'active' : ''" v-if="getLoggedInUser.role === USER_ROLES.ADMIN">
                <a v-b-toggle.users class="svg-icon">
                  <i class="fas fa-user text-primary" />
                  <span class="ml-2">{{ $t('userMsgs.users') }}</span>
                  <i class="fas fa-angle-right mm-arrow-right arrow-active"></i>
                  <i class="fas fa-angle-down mm-arrow-right arrow-hover"></i>
                </a>
                <b-collapse tag="ul" id="users" class="submenu" accordion="child-accordion">
                  <li :class="checkActive('list-users') ? 'active' : ''">
                    <router-link :to="{ name: 'list-users' }" class="svg-icon">
                      <i class="far fa-list-alt text-primary" />
                      <span class="">{{ $t('userMsgs.listUsers') }}</span>
                    </router-link>
                  </li>
                  <li :class="checkActive('add-user') ? 'active' : ''">
                    <router-link :to="{ name: 'add-user' }" class="svg-icon">
                      <i class="fas fa-user-plus text-primary" />
                      <span class="">{{ $t('userMsgs.addUser') }}</span>
                    </router-link>
                  </li>
                </b-collapse>
              </li>

              <!-- Materials -->
              <!-- <li
                :class="checkActive(materials) ? 'active' : ''"
                v-if="
                  [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(
                    getLoggedInUser.role
                  )
                "
              >
                <a v-b-toggle.materials class="svg-icon">
                  <i class="fas fa-cubes text-primary" />
                  <span class="ml-2">{{ $t('materialMsgs.materials') }}</span>
                  <i class="fas fa-angle-right mm-arrow-right arrow-active"></i>
                  <i class="fas fa-angle-down mm-arrow-right arrow-hover"></i>
                </a>
                <b-collapse tag="ul" id="materials" class="submenu" accordion="child-accordion">
                  <li :class="checkActive('list-materials') ? 'active' : ''">
                    <router-link :to="{ name: 'list-materials' }" class="svg-icon">
                      <i class="fas fa-cubes text-primary" />
                      <span class="">{{ $t('materialMsgs.listMaterials') }}</span>
                    </router-link>
                  </li>
                  <li :class="checkActive('add-material') ? 'active' : ''">
                    <router-link :to="{ name: 'add-material' }" class="svg-icon">
                      <i class="fas fa-plus-circle text-primary"></i
                      ><span class="">{{ $t('materialMsgs.addMaterial') }}</span>
                    </router-link>
                  </li>
                </b-collapse>
              </li> -->

              <!-- Customers -->
              <li
                :class="checkActive(customers) ? 'active' : ''"
                v-if="
                  [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(
                    getLoggedInUser.role
                  )
                "
              >
                <a v-b-toggle.customers class="svg-icon">
                  <i class="fas fa-briefcase text-primary" />
                  <span class="ml-2">{{ $t('customerMsgs.customers') }}</span>
                  <i class="fas fa-angle-right mm-arrow-right arrow-active"></i>
                  <i class="fas fa-angle-down mm-arrow-right arrow-hover"></i>
                </a>
                <b-collapse tag="ul" id="customers" class="submenu" accordion="child-accordion">
                  <li :class="checkActive('list-customers') ? 'active' : ''">
                    <router-link :to="{ name: 'list-customers' }" class="svg-icon">
                      <i class="fas fa-briefcase text-primary" />
                      <span class="">{{ $t('customerMsgs.listCustomers') }}</span>
                    </router-link>
                  </li>
                  <li :class="checkActive('add-customer') ? 'active' : ''">
                    <router-link :to="{ name: 'add-customer' }" class="svg-icon">
                      <i class="fas fa-plus-circle text-primary"></i
                      ><span class="">{{ $t('customerMsgs.addCustomer') }}</span>
                    </router-link>
                  </li>
                </b-collapse>
              </li>

              <!-- Suppliers -->
              <!-- <li
                :class="checkActive(suppliers) ? 'active' : ''"
                v-if="
                  [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(
                    getLoggedInUser.role
                  )
                "
              >
                <a v-b-toggle.suppliers class="svg-icon">
                  <i class="fas fa-industry text-primary"></i>
                  <span class="ml-2">{{ $t('supplierMsgs.suppliers') }}</span>
                  <i class="fas fa-angle-right mm-arrow-right arrow-active"></i>
                  <i class="fas fa-angle-down mm-arrow-right arrow-hover"></i>
                </a>
                <b-collapse tag="ul" id="suppliers" class="submenu" accordion="child-accordion">
                  <li :class="checkActive('list-suppliers') ? 'active' : ''">
                    <router-link :to="{ name: 'list-suppliers' }" class="svg-icon">
                      <i class="fas fa-industry text-primary" />
                      <span class="">{{ $t('supplierMsgs.listSuppliers') }}</span>
                    </router-link>
                  </li>
                  <li :class="checkActive('add-supplier') ? 'active' : ''">
                    <router-link :to="{ name: 'add-supplier' }" class="svg-icon">
                      <i class="fas fa-plus-circle text-primary"></i
                      ><span class="">{{ $t('supplierMsgs.addSupplier') }}</span>
                    </router-link>
                  </li>
                </b-collapse>
              </li> -->

              <!-- Wage Management -->
              <li
                :class="checkActive('list-hourly-wages') ? 'active' : ''"
                v-if="
                  [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(
                    getLoggedInUser.role
                  )
                "
              >
                <router-link :to="{ name: 'list-hourly-wages' }" class="svg-icon">
                  <i class="fas fa-money-check-alt text-primary" />
                  <span>{{ $t('hourlyWageMsgs.hourlyWage') }}</span>
                </router-link>
              </li>

              <!-- Alerts Management -->
              <li
                :class="checkActive('list-email-alerts') ? 'active' : ''"
                v-if="getLoggedInUser.role === USER_ROLES.ADMIN"
              >
                <router-link :to="{ name: 'list-email-alerts' }" class="svg-icon">
                  <i class="fas fa-envelope text-primary" />
                  <span>{{ $t('alertsConfigurationMsgs.emailAlerts') }}</span>
                </router-link>
              </li>
            </b-collapse>
          </li>
        </ul>
      </nav>

      <!-- <div id="sidebar-bottom" class="position-relative sidebar-bottom">
        <div class="card bg-primary rounded">
          <div class="card-body">
            <div class="sidebarbottom-content">
              <div class="image">
                <img
                  src="@/assets/images/layouts/layout-1/side-bkg.png"
                  class="img-fluid"
                  alt="side-bkg"
                />
              </div>
              <h5 class="mb-3 text-white mt-3">Did you Know ?</h5>
              <p class="mb-0 text-white">
                You can add additional user in your Account's Settings
              </p>
              <button type="button" class="btn btn-dark mt-3">
                New Program
              </button>
            </div>
          </div>
        </div>
      </div> -->

      <div class="pt-5 pb-2"></div>
    </div>

    <order-results-modal :showModal.sync="showOrderResultsModal" @close="hideOrderResultsModal" />

    <invoices-export-modal :showModal.sync="showExportInvoicesModal" @close="hideExportInvoicesModal" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { core } from '@/config/pluginInit';
import { USER_ROLES } from '../../../common/constants';
import OrderResultsModal from '../../../views/Orders/OrderResults/OrderResutsModal';
import InvoicesExportModal from '../../../views/CustomerInvoices/InvoiceExportModal.vue';

export default {
  name: 'Sidebar',
  props: {
    items: Array,
  },
  data() {
    return {
      USER_ROLES,

      isCollapsed: false,
      showOrderResultsModal: false,
      showExportInvoicesModal: false,

      homeurl: '',
      user: ['list-users', 'add-user', 'edit-user'],
      orders: ['list-orders', 'add-order', 'edit-order'],
      suppliers: ['list-suppliers', 'add-supplier', 'edit-supplier'],
      materials: ['list-materials', 'add-material', 'edit-material'],
      customers: ['list-customers', 'add-customer', 'edit-customer'],
      inventoryReceipts: ['list-inventory-receipts', 'add-inventory-receipt', 'edit-inventory-receipt'],
      customerInvoices: ['list-customer-invoices', 'add-customer-invoice', 'edit-customer-invoice'],
    };
  },

  components: { OrderResultsModal, InvoicesExportModal },

  mounted() {
    core.SmoothScrollbar();

    // Setting collapsed sidebar status.
    this.isCollapsed = !!core.checkElement('class', 'sidebar-main');
  },
  destroyed() {
    core.SmoothScrollbar();
  },
  computed: {
    ...mapGetters({
      // logo: "darklogo",
      // sidelogo: "logo",
      getLoggedInUser: 'getLoggedInUser',
    }),
    settings() {
      return [].concat(
        this.user,
        this.materials,
        this.customers,
        this.suppliers,
        ['list-hourly-wages'],
        ['list-email-alerts']
      );
    },
  },
  methods: {
    checkActive(route) {
      if (this.$route.name == route) {
        return true;
      }
    },
    miniSidebar() {
      core.triggerSet();

      // Setting collapsed sidebar status.
      this.isCollapsed = !!core.checkElement('class', 'sidebar-main');
    },

    // openOrderResultsModal() {
    //   this.showOrderResultsModal = true;
    // },

    hideOrderResultsModal() {
      this.showOrderResultsModal = false;
    },

    openExportInvoicesModal() {
      this.showExportInvoicesModal = true;
    },

    hideExportInvoicesModal() {
      this.showExportInvoicesModal = false;
    },

    showCollapse(collapseId) {
      document.getElementById(collapseId).closest('li').classList.add('active');
    },
    hideCollapse() {
      const list = document.querySelector('.mm-sidebar-menu').querySelectorAll('.submenu');
      Array.from(list, (elem) => {
        if (elem.closest('.active') !== null && elem.closest('.active') !== undefined) {
          elem.closest('.active').classList.remove('active');
        }
      });
    },
  },
};
</script>
