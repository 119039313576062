<template>
  <div>
    <!-- <Loader /> -->
    <div class="wrapper">
      <Sidebar />
      <Header />
      <div class="content-page">
        <transition name="router-anim">
          <router-view />
        </transition>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
// import Loader from "@/components/loader/Loader";
import Sidebar from "@/components/partials/Sidebar/Sidebar";
import Header from "@/components/partials/Header/Header";
import Footer from "@/components/partials/Footer/Footer";
export default {
  name: "Layout",
  components: {
    Header,
    Footer,
    Sidebar,
    // Loader,
  },
  mounted() {
    document.body.classList = "";
    // core.iconanimate();
  },
  destroyed() {
    document.body.classList = "";
  },
};
</script>
<style>
</style>
