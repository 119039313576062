<template>
  <li v-nav-toggle>
    <a
      href="#"
      class="search-toggle dropdown-toggle"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click.prevent
    >
      <b-avatar
        variant="light"
        style="background-color: transparent !important; width: 1.5rem;"
        badge-top
        badge-variant="secondary"
      >
        <svg
          class="feather feather-bell svg-icon text-primary"
          id="mm-bell-2"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
        </svg>
        <template #badge v-if="unreadCount">
          <span class="align-text-bottom">{{ unreadCount }}</span>
        </template>
      </b-avatar>
      <span class="bg-primary"></span>
    </a>
    <ul class="mm-sub-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      <div class="card shadow-none m-0 border-0" style="height: 400px">
        <div class="cust-title p-3 d-flex justify-content-between" >
          <h5 class="mb-0">
            {{ $t('notificationMsgs.allNotifications') }}
            {{ unreadCount ? `(${$n(unreadCount, 'number', 'de-DE')})` : '' }}
          </h5>
          <a href="#" @click.prevent="markAllNotificationsRead">
            <i class="far fa-envelope-open" v-b-popover.hover.top="$t('notificationMsgs.markAllRead')"></i>
          </a>
        </div>
        <div
          class="card-body p-2 overflow-auto"
          :class="!totalNotifications ? 'd-flex justify-content-center align-items-center' : ''"
        >
          <notification-list-item
            v-for="(notification, index) in allNotifications"
            :key="index"
            :notification="notification"
            class="mb-1 dropdown-item d-flex svg-icon text-wrap"
            @notificationRead="notificationReadAction"
          />
          <infinite-loading @infinite="infiniteLoadHandler">
            <div slot="spinner">
              <div class="text-center">
                <i class="fas fa-circle-notch fa-spin" />
              </div>
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
          <p class="ml-1" v-if="!totalNotifications">{{ $t('notificationMsgs.noNotifications') }}</p>
        </div>
      </div>
    </ul>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import { formatDateTime, NOTIFICATION_EXTRA_MAPPINGS } from '../../../../common/utils';
import { USER_ROLES } from '../../../../common/constants';
import { envConfig } from '../../../../config/envConfig';
import NotificationListItem from './NotificationListItem.vue';

export default {
  name: 'Notifications',

  data() {
    return {
      allNotifications: [],
      pageNum: 1,
      pageSize: 20,
      isLoadingMore: false,
      areNotificationsLoading: false,
      connection: null,
      unreadCount: 0,
      totalNotifications: 0
    };
  },

  components: {
    NotificationListItem,
    InfiniteLoading
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getLoggedInUser'])
  },

  methods: {
    ...mapActions(['getAllNotifications', 'updateNotification', 'getBillPaper', 'getComment', 'markAllRead']),
    NOTIFICATION_EXTRA_MAPPINGS,
    formatDateTime,

    async markAllNotificationsRead() {
      const response = this.markAllRead();
      this.unreadCount = response.unread_count;
      for (const notification of this.allNotifications) {
        notification.is_read = true;
      }
    },

    async fetchAndSetNotifications(params = {}) {
      this.areNotificationsLoading = true;
      const response = await this.getAllNotifications({
        limit: this.pageSize,
        offset: (this.pageNum - 1) * this.pageSize,
        ...params
      });
      if (response.data.results) {
        this.allNotifications.push(...response.data.results);
        this.totalNotifications = response.data.count;
      }
      this.unreadCount = response.data.unread_count;

      this.areNotificationsLoading = false;
    },

    async loadMore() {
      this.isLoadingMore = true;
      this.pageNum++;
      await this.fetchAndSetNotifications();
      this.isLoadingMore = false;
    },

    async infiniteLoadHandler($state) {
      if (this.allNotifications.length < this.totalNotifications) {
        await this.loadMore();
        $state.loaded();
      } else $state.complete();
    },

    async uploadBillHandler(data, bill_id) {
      this.$emit('showOrderModal', { id: data.order_id, order_number: data.order_number, selectedTab: 2});

      const response = await this.getBillPaper(bill_id);
      if (response.data.is_archived) {
        this.makeToast('warning', this.$t('notificationMsgs.billArchived'));
      }
    },

    async mentionedCommentHandler(data, comment_id) {
      this.$emit('showOrderModal', { id: data.order_id, order_number: data.order_number, selectedTab: 4, commentId: comment_id });

      try {
        await this.getComment(comment_id);
      } catch (err) {
        this.makeToast('warning', this.$t('notificationMsgs.commentDeleted'));
      }
    },

    notificationReadAction(notification, data, ...args) {
      return {
        uploaded_bill: this.uploadBillHandler,
        mentioned_comment: this.mentionedCommentHandler
      }[notification](data, ...args);
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500
      });
    }
  },

  mounted() {
    this.fetchAndSetNotifications();
  },

  created() {
    if (this.getLoggedInUser.role !== USER_ROLES.CALCULATOR) {
      var ws_scheme = envConfig.djangoServerProtocol == 'https' ? 'wss' : 'ws';
      this.connection = new WebSocket(
        `${ws_scheme}://${envConfig.djangoServerAddr}/ws/notifications/${this.getLoggedInUser.id}/?token=${this.getAuthToken}`
      );
      this.connection.onmessage = event => {
        const data = JSON.parse(event.data).message;
        if (data.action === 'Send') {
          this.allNotifications.unshift(data.notification);
          this.unreadCount = data.unread_count;
          this.totalNotifications++;
          this.makeToast('info', this.$t('notificationMsgs.notificationReceived'));
        } else if (data.action === 'Update') {
          let notificationToUpate = this.allNotifications.find(
            notification => notification.id === data.notification.id
          );
          notificationToUpate.is_read = data.notification.is_read;
          this.unreadCount = data.unread_count;
        }
      };
      this.connection.onerror = () => {};
    }
  },

  destroyed() {
    if (this.connection) this.connection.close();
  }
};
</script>
